import React from 'react';
import { Grid, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const CDateField = ({ title, id, formFields, fieldName, handler }) => {
    return (
        <Grid item xs={12} md={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                    id={id}
                    label={title}
                    size="small"
                    value={formFields[fieldName]} 
                    onChange={handler}
                    renderInput={(params) => <TextField {...params} variant='standard' fullWidth />} // Added fullWidth here                    
                />
            </LocalizationProvider>
        </Grid>
    );
};

export default CDateField;
